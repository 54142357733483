import React, { useCallback, useEffect, useRef } from "react";
type ModalAlertProp = {
  type: "success" | "fail";
  title: string;
  subtitle?: string;
  closeModal: () => void;
};
const ModalAlert = ({ type, title, subtitle, closeModal }: ModalAlertProp) => {
  const alertRef = useRef<HTMLDivElement | null>(null);

  // close modalAlert on clicking outside it
  const closeAlert = useCallback((e: any) => {
    if (alertRef.current && !alertRef.current?.contains(e.target)) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", closeAlert);
  }, [closeAlert]);

  return (
    <div className="z-40 fixed w-screen h-screen top-0 left-0 bg-white/20 backdrop-blur-sm ">
      <div
        ref={alertRef}
        className="w-80 px-4 py-10 bg-white absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl flex flex-col justify-center items-center sm:w-[400px] sm:px-7 md:py-12 md:w-[500px] md:px-10"
      >
        {/* close icon */}
        <svg
          onClick={closeModal}
          className="absolute right-7 top-7 cursor-pointer"
          width="14"
          height="14"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.9999 25.7598L2.23438 2"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.7656 2.24023L2 26"
            stroke="#333333"
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {/* success check icon */}
        {type === "success" && (
          <svg
            className="mx-auto mb-2  md:mb-4"
            width="100"
            height="100"
            viewBox="0 0 145 145"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="72.5" cy="72.5" r="72.5" fill="#efdcce" />
            <circle cx="72.5" cy="72.5" r="55.5" fill="#F58634" />
            <path
              d="M59.668 73.5229L69.0961 82.951L87.9522 64.0949"
              stroke="white"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
        )}

        <p className="text-xl text-center font-semibold my-2">
          Your response has been received
        </p>

        <p className="font-light text-center tracking-normal">
          We will be in touch with more information on how you can get involved
        </p>
      </div>
    </div>
  );
};

export default ModalAlert;
