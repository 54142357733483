import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/SVGs/tlnt-pro-black.svg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavbarBlack = () => {
  let navigate = useNavigate();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  // disable scroll on body  when navbar is open
  isNavbarOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  // close mobile navbar when window's width is greater than tailwind's default large screen size (1024px)
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 1024) setIsNavbarOpen(false);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      {isNavbarOpen && (
        <div
          onClick={() => setIsNavbarOpen(false)}
          className="z-40 fixed  w-screen h-screen top-0 left-0 bg-white/20 backdrop-blur-sm lg:hidden"
        ></div>
      )}
      <nav className=" w-full flex justify-between items-center p-5 md:py-10 md:px-14 lg:px-20">
        <Logo
          onClick={() => navigate("/")}
          className="w-28 h-8 cursor-pointer"
        />
        <ul
          className={`bg-white/90 w-80 rounded-lg text-black font-semibold fixed z-40 top-5 right-5 flex flex-col gap-10 px-10 py-12  md:px-16 md:top-10 md:right-10 md:w-96 lg:bg-transparent lg:w-auto lg:text-black lg:backdrop-blur-none lg:font-medium lg:flex-row lg:h-auto lg:p-0 lg:flex lg:static ${
            isNavbarOpen ? "block" : "hidden"
          }`}
        >
          <li>
            <svg
              onClick={() => setIsNavbarOpen(false)}
              className="absolute right-7 top-7 cursor-pointer lg:hidden"
              width="14"
              height="14"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.9999 25.7598L2.23438 2"
                stroke="#333333"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.7656 2.24023L2 26"
                stroke="#333333"
                strokeWidth="3"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </li>
          <li className="tracking-widest text-sm uppercase cursor-pointer ">
            <HashLink
              to="/#about"
              smooth
              className="hover:text-primary_light"
              onClick={() => setIsNavbarOpen(false)}
            >
              About Us
            </HashLink>
          </li>
          <li className="tracking-widest text-sm uppercase cursor-pointer ">
            <HashLink
              to="/#works"
              smooth
              className="hover:text-primary_light"
              onClick={() => setIsNavbarOpen(false)}
            >
              Our Works
            </HashLink>
          </li>
          <li className="tracking-widest text-sm uppercase cursor-pointer ">
            <HashLink
              to="/#partners"
              smooth
              className="hover:text-primary_light"
              onClick={() => setIsNavbarOpen(false)}
            >
              Partners
            </HashLink>
          </li>
          <li className="tracking-widest text-sm uppercase cursor-pointer ">
            <HashLink
              to="/#get-involved"
              smooth
              className="hover:text-primary_light"
              onClick={() => setIsNavbarOpen(false)}
            >
              Get Involved
            </HashLink>
          </li>
        </ul>

        {/* hamburger menu */}
        {!isNavbarOpen && (
          <svg
            onClick={() => setIsNavbarOpen(true)}
            className="lg:hidden cursor-pointer fill-black hover:fill-primary transition"
            width="30"
            height="12"
            viewBox="0 0 30 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="0.0285645" width="29.7143" height="1" rx="0.5" />
            <rect x="7.71436" y="5.17139" width="22" height="1" rx="0.5" />
            <rect x="14.8571" y="10.6" width="15.1429" height="1" rx="0.5" />
          </svg>
        )}
      </nav>
    </>
  );
};

export default NavbarBlack;
