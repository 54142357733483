const Works = () => {
  return (
    <section
      id="works"
      className="bg-gradient-to-br from-[#dae2eb] via-[#f5f2f0] to-[#e6ae89] w-full px-5 py-14 gap-10 md:px-14 md:py-20 lg:px-20 "
    >
      <h3 className="uppercase tracking-widest text-center mb-7 text-sm font-medium md:text-base ">
        Our works
      </h3>
      <div className="flex flex-col gap-5 md:flex-row  justify-center md:flex-wrap  md:gap-6 lg:gap-x-14 lg:gap-y-10">
        <div className="bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 lg:w-[410px]">
          <p className="text-primary text-3xl tracking-widest font-bold mb-2">
            01.
          </p>
          <p className="font-semibold text-xl tracking-wider mb-3">
            Recruitment Program
          </p>
          <p className="tracking-wide font-light leading-relaxed">
            The Blache Talent Program is actively seeking out and recruiting
            top-tier tech experts across Africa to work remotely with firms from
            around the world. This program is aimed at bridging the gap between
            African tech talent and the global market, providing talented
            individuals with opportunities to showcase their skills and work on
            international projects.
          </p>
        </div>
        <div className="bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 lg:w-[410px]">
          <p className="text-primary text-3xl tracking-widest font-bold mb-2">
            02.
          </p>
          <p className="font-semibold text-xl tracking-wider mb-3">Portal</p>
          <p className="tracking-wide font-light leading-relaxed">
            Portal is a web and mobile app platform
            that connects African tech talents with global firms. The platform
            provides a space where companies can easily search for and hire
            experts in emerging markets for various projects, while also
            allowing individuals to showcase their skills and connect with
            potential clients. Through this platform, Blache is helping to
            promote African tech talent on a global scale.
          </p>
        </div>
        <div className="bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 lg:w-[410px]">
          <p className="text-primary text-3xl tracking-widest font-bold mb-2">
            03.
          </p>
          <p className="font-semibold text-xl tracking-wider mb-3">A-LEAP</p>
          <p className="tracking-wide font-light leading-relaxed">
            A Laptop for Every African Program (A-LEAP) is an initiative under
            the Blache Talent Program that aims to provide laptops to talented
            and ambitious African individuals who lack access to the necessary
            tools and resources needed to develop their tech skills. By
            providing laptops to those who need them, Blache hopes to empower
            African individuals with the means to pursue their dreams and
            achieve their potential in the tech industry.
          </p>
        </div>
        <div className="bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 lg:w-[410px]">
          <p className="text-primary text-3xl tracking-widest font-bold mb-2">
            04.
          </p>
          <p className="font-semibold text-xl tracking-wider mb-3">
            Training Initiatives
          </p>
          <p className="tracking-wide font-light leading-relaxed">
            In addition to recruitment and job placement services, the Blache
            Talent Program offers various training initiatives designed to help
            African tech talents develop their skills and stay up-to-date with
            the latest trends and technologies in the industry. These training
            programs include online courses, webinars, and workshops, and are
            available to individuals who are part of the Blache Talent Program,
            as well as those who are interested in joining. By providing these
            training opportunities, Blache is helping to ensure that African
            tech talents are equipped with the skills and knowledge needed to
            succeed in a rapidly-evolving industry.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Works;
