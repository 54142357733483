import AboutImage from "../assets/images/image-1.jpg";

const About = () => {
  return (
    <section
      id="about"
      className="bg-gradient-to-br from-[#dae2eb] via-[#f5f2f0] to-[#e6ae89] w-full px-5 py-14  gap-10 flex flex-col md:items-center md:px-14 md:py-20 lg:px-20 lg:flex-row lg:justify-between lg:items-stretch"
    >
      <div className="w-full lg:w-3/6">
        <h3 className="uppercase tracking-widest font-medium mb-5 text-sm md:text-base">
          About us
        </h3>
        <div className="w-full mb-8 bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 ">
          <p className="text-xl font-semibold tracking-wider text-primary mb-4 md:text-2xl">
            Blache
          </p>
          <p className="leading-relaxed tracking-wide">
            Africa’s Tech Company. We are a group of engineers and
            professionals, with a knack for solving problems; we want to improve
            the living experience in Africa.
          </p>
          <a
            href="https://blacheinc.com/"
            target={"_blank"}
            rel="noreferrer"
            className="underline mt-2 inline-block underline-offset-4 text-sm tracking-wider transition hover:text-[#F58634]"
          >
            Learn more
          </a>
        </div>
        <div className="w-full bg-white/25 border border-white/40 py-5 px-5 backdrop-blur-md shadow-sm rounded-xl md:px-7 ">
          <p className="text-xl font-semibold tracking-wider text-primary mb-4 md:text-2xl ">
            The Talent Program
          </p>
          <p className="leading-relaxed  tracking-wide">
            The Blache Talent Program is an initiative by Blache towards growing
            the tech capacity in Africa through a 3-dimensional approach to
            equip young Africans with training tools and resources, to provide
            opportunities for remote work and to build communities that
            proliferate the emergence of timely solutions.
          </p>
          <a
            href="https://twitter.com/i/communities/1642986481210851328"
            target={"_blank"}
            rel="noreferrer"
            className="underline mt-2 inline-block underline-offset-4 text-sm tracking-wider transition hover:text-[#F58634]"
          >
            Join our twitter community
          </a>
        </div>
      </div>
      <img
        src={AboutImage}
        className="w-full object-cover md:w-4/6 lg:w-3/6"
        alt=""
      />
    </section>
  );
};

export default About;
