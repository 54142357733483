import React from "react";

const VideoIllustration = () => {
  return (
    <section className=" bg-gradient-to-br from-[#121212] via-[#282828] to-[#121212]  w-full px-5 py-14  md:px-14 md:py-20 lg:px-20 ">
      <h3 className="uppercase tracking-widest text-white text-center mb-7 text-sm md:text-base">
        Dear African Engineers
      </h3>

      <video controls className="shadow-md mx-auto lg:w-2/3">
        <source
          src={
            "https://storage.googleapis.com/blache-storage/DEAR%20AFRICAN%20ENGINEER.mp4"
          }
          type="video/mp4"
        />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </section>
  );
};

export default VideoIllustration;
