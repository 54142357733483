import { Route, Routes } from "react-router-dom";
import Error404 from "../pages/Error404";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const TlntRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default TlntRoutes;
