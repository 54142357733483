import React from "react";
import { ReactComponent as Logo } from "../assets/SVGs/tlnt-pro.svg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  let navigate = useNavigate();

  return (
    <footer className="px-5 py-10 bg-gradient-to-br from-[#121212] via-[#1d1d1d] to-[#121212] md:px-14 md:py-16  lg:px-20 ">
      <section className="mb-14 flex gap-x-5 gap-y-10 flex-wrap justify-between items-start">
        <div className="w-full sm:max-w-[300px]">
          <Logo
            onClick={() => navigate("/")}
            className="w-36 h-8 mb-4 cursor-pointer"
          />
          <p className="text-white tracking-wide leading-relaxed">
            Creating a thriving tech ecosystem in Africa, driven by a diverse
            and talented pool of professionals.
          </p>
        </div>

        <div className="w-full sm:max-w-[250px]">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/blacheinc/"
                target="_blank"
                rel="noreferrer"
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/BlacheInc"
                target="_blank"
                rel="noreferrer"
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/BlacheInc"
                target="_blank"
                rel="noreferrer"
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
        <div className="w-full sm:max-w-[250px]">
          {/* <p className="uppercase text-white font-semibold mb-4 tracking-wider">
            Quick links
          </p> */}
          <ul>
            <li>
              <HashLink
                to="/#about"
                smooth
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                About Us
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#works"
                smooth
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Works
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#partners"
                smooth
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Partners
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/#get-involved"
                smooth
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Get Involved
              </HashLink>
            </li>
            <li>
              <HashLink
                to="/privacy-policy"
                smooth
                className="text-white uppercase text-sm tracking-wider inline-block mb-2 transition hover:text-primary_light"
              >
                Privacy Policy
              </HashLink>
            </li>
          </ul>
        </div>
      </section>
      <p className="text-[#868686] text-center">
        &copy; 2023 Wired Intelligence Limited ({" "}
        <a
          href="https://blacheinc.com/"
          target={"_blank"}
          rel="noreferrer"
          className="underline underline-offset-2 transition hover:text-primary_light"
        >
          BLACHE
        </a>
        {"  "}
        ), All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
