const Hero = () => {
  return (
    <div className="py-10 px-5 md:px-14 lg:px-20">
      <h1 className="text-4xl font-bold mb-3 capitalize leading-none tracking-wider text-white drop-shadow-md md:text-6xl md:mb-6 ">
        The <br /> talent <br /> Program
      </h1>
      <h2 className="text-lg text-white drop-shadow-md mb-5 leading-tight tracking-wide md:text-xl md:mb-8">
        A tech talent development initiative focused on Africa.
      </h2>
      <div className="relative">
        <a href="/#get-involved">
          <button className="w-48 h-12 uppercase font-medium tracking-widest text-white border border-white outline-none bg-transparent select-none hover:bg-white hover:text-black transition rounded-sm">
            Get involved
          </button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
