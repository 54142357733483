import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import NavbarBlack from "../layouts/NavbarBlack";
const Error404 = () => {
  let navigate = useNavigate();
  return (
    <main className="bg-[#fffcfa]  ">
      <NavbarBlack />
      <section className="px-5 py-14 md:p-20">
        <p className="text-7xl font-bold text-center text-primary mb-6 select-none md:text-8xl">
          404
        </p>
        <h1 className="capitalize text-center text-2xl font-semibold mb-4 md:text-3xl">
          Page not found
        </h1>
        <p className="tracking-wider text-center leading-relaxed w-full sm:w-[580px] sm:mx-auto">
          The page you are looking for doesn't exist. It may have been moved or
          removed altogether. Please return to the website's homepage to find
          what you're looking for.
        </p>
        <button
          onClick={() => navigate("/")}
          className="w-52 h-12 my-5 block mx-auto text-sm uppercase font-medium tracking-widest text-black border border-black outline-none bg-transparent select-none hover:bg-primary hover:text-white hover:border-none transition rounded-sm"
        >
          Back to home
        </button>
      </section>
      <Footer />
    </main>
  );
};

export default Error404;
