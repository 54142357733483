import React from "react";

type TextFieldProps = {
  type?: string;
  title?: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  placeholder?: string;
  isRequired?: boolean;
};

const TextField = ({
  type = "text",
  title,
  handleInputChange,
  value,
  name,
  placeholder,
  isRequired,
}: TextFieldProps) => {
  return (
    <label className=" h-full w-full ">
      <p className="mb-2 tracking-wide text-dark font-medium capitalize">
        {title} {isRequired && "*"}
      </p>
      <input
        name={name}
        type={type}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className=" tracking-wide outline-none rounded-sm items-start h-full w-full bg-transparent border-2 border-[#ababab] font-light text-dark py-[10px] px-4 placeholder:text-sm focus:border-primary "
      />
    </label>
  );
};

export default TextField;
