import { useEffect } from "react";
import Footer from "../layouts/Footer";
import NavbarBlack from "../layouts/NavbarBlack";

const PrivacyPolicy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <main className="bg-gradient-to-br from-[#dae2eb] via-[#f5f2f0] to-[#e6ae89]">
      <div className="relative">
        <NavbarBlack />
      </div>

      <section className=" px-5 py-10 md:px-20 md:py-10 ">
        <h1 className="uppercase tracking-widest mb-2 text-sm md:text-base">
          THE TALENT PROGRAM PRIVACY POLICY
        </h1>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Privacy policy
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            This privacy policy describes how we collect, use, and protect the
            personal information you provide to us when you use our website and
            submit the Blache Talent Program support form. By using our website
            and submitting the form, you agree to the terms of this privacy
            policy.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Information Collection and Use
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            We collect personal information such as your name, email, phone
            number, and country when you submit the support form. We will use
            this information to contact you regarding your interest in
            supporting the Blache Talent Program. We will not share your
            personal information with any third-party entities unless required
            by law
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Cookies
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            Our website uses cookies to improve your browsing experience.
            Cookies are small text files that are placed on your device by a
            website. We use cookies to analyze website traffic and usage, as
            well as to personalize your browsing experience. You can disable
            cookies in your browser settings if you do not wish to have them
            placed on your device.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Data Security
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            We take appropriate measures to protect the personal information you
            provide to us. However, we cannot guarantee that your personal
            information will be completely secure from unauthorized access,
            disclosure, or use. By using our website and submitting the support
            form, you acknowledge and accept these risks.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Changes to Privacy Policy
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            We may update this privacy policy from time to time. Any changes to
            the policy will be posted on our website. We encourage you to review
            the policy periodically to stay informed of any updates.
          </p>
        </div>
        <div className="mb-10">
          <h3 className="text-lg font-semibold tracking-wider text-primary mb-4 md:text-xl">
            Contact Us
          </h3>
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            If you have any questions or concerns regarding this privacy policy,
            please contact us at{" "}
            <span className="whitespace-nowrap">
              {" "}
              talent-program@blacheinc.com
            </span>
            .
          </p>
        </div>
        <div className="mb-10">
          <p className=" tracking-wide leading-relaxed md:w-5/6 lg:w-4/6">
            By submitting the Blache Talent Program support form, you
            acknowledge that you have read and understood this privacy policy
            and agree to the collection, use, and protection of your personal
            information as outlined above.
          </p>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default PrivacyPolicy;
