import Andela from "../assets/images/andela_logo.png";
import GabLotto from "../assets/images/gab_lotto_logo.png";
import TrustHospital from "../assets/images/the_trust_hospital_logo.png";
import Underworld from "../assets/images/underworld_logo.png";
import Flutterwave from "../assets/images/flutterwave.png";
import Hp from "../assets/images/hp.png";
import IbmTwc from "../assets/images/ibm-twc.png";
import Kyzzen from "../assets/images/kyzzen.png";
import SamLabs from "../assets/images/sam-labs.png";
import YumBrands from "../assets/images/yum-brands.png";

import PartnersImage from "../assets/images/happy_partners.jpg";

const Partners = () => {
  return (
    <section
      id="partners"
      className="bg-gradient-to-br from-[#121212] via-[#282828] to-[#121212] w-full px-5 py-14 gap-10 flex flex-col md:items-center md:px-14 md:py-20 lg:px-20 lg:flex-row lg:justify-between lg:items-stretch"
    >
      <img
        src={PartnersImage}
        className="w-full object-cover md:w-4/6 lg:w-3/6"
        alt=""
      />
      <div className="w-full lg:w-3/6">
        <h3 className="uppercase tracking-widest mb-2 text-primary text-sm md:text-base">
          Partners
        </h3>
        <p className="text-xl font-semibold tracking-wider text-white mb-14 md:text-2xl ">
          We have the privilege of working with some global game-changers
        </p>
        <div className="flex flex-wrap gap-12 justify-center">
          {" "}
          <img
            src={IbmTwc}
            alt="Ibm_twc"
            className="w-[120px] object-contain"
          />
          <img src={Hp} alt="hp" className="w-14 object-contain" />
          <img
            src={YumBrands}
            alt="Yum_brands"
            className="w-48 object-contain"
          />
          <img src={Andela} alt="andela_logo" className="w-32 object-contain" />
          <img
            src={GabLotto}
            alt="gab_lotto_logo"
            className="w-32 object-contain"
          />
          <img
            src={Flutterwave}
            alt="flutter_wave"
            className="w-28 object-contain"
          />
          <img
            src={TrustHospital}
            alt="the_trust_hospital_logo"
            className="w-36 object-contain"
          />
          <img
            src={Underworld}
            alt="underworld_logo"
            className="w-20 object-contain"
          />
          <img src={Kyzzen} alt="Kyzzen" className="w-24 object-contain" />
          <img src={SamLabs} alt="Sam_labs" className="w-20 object-contain" />
        </div>
      </div>
    </section>
  );
};

export default Partners;
