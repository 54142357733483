import React, { useCallback, useEffect, useRef, useState } from "react";
import TextField from "./form/TextField";
import DonationImage from "../assets/images/support_tlnt_pro.jpg";
import CustomCheckbox from "./form/CustomCheckbox";
import ModalAlert from "./ModalAlert";

const Donation = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);
  const [inputValues, setInputValues] = useState({
    full_name: "",
    email: "",
    phone: "",
    in_kind_method: "",
    volunteer_method: "",
  });
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);

  const [isMonetaryDonationChecked, setIsMonetaryDonationChecked] =
    useState(false);
  const [isInKindDonationChecked, setIsInKindDonationChecked] = useState(false);
  const [isVolunteerWorkChecked, setIsVolunteerWorkChecked] = useState(false);

  const [response, setResponse] = useState({
    type: "",
    text: "",
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  // close dropdown on clicking outside it
  const closeDropdown = useCallback(
    (e: any) => {
      if (
        dropdownRef.current &&
        isCountryDropdownOpen &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsCountryDropdownOpen(false);
      }
    },
    [isCountryDropdownOpen]
  );
  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
  }, [closeDropdown]);

  //   list of all countries
  const countryList = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    console.log("input values", inputValues);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsButtonLoading(true);

    let formData = new FormData();

    formData.append("full_name", inputValues.full_name);
    formData.append("email", inputValues.email);
    formData.append("phone", inputValues.phone);
    formData.append("country", selectedCountry);
    formData.append(
      "monetary_donation",
      isMonetaryDonationChecked ? "yes" : ""
    );
    formData.append("in_kind_donation", isInKindDonationChecked ? "yes" : "");
    formData.append("volunteer_work", isVolunteerWorkChecked ? "yes" : "");
    formData.append("in_kind_method", inputValues.in_kind_method);
    formData.append("volunteer_method", inputValues.volunteer_method);

    fetch(
      "https://script.google.com/macros/s/AKfycbzta9Zz46kVOdo4ihoabjqByzTZyTXte-ara_AIc0gYa1SyFxsa7wftQUgqfXAfq6ZoWA/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.text())
      .then((data) => {
        // set button loading state to false
        setIsButtonLoading(false);
        formRef.current?.reset();

        // reset input fields to default
        setInputValues({
          full_name: "",
          email: "",
          phone: "",
          in_kind_method: "",
          volunteer_method: "",
        });

        // uncheck all checkboxes
        setIsMonetaryDonationChecked(false);
        setIsInKindDonationChecked(false);
        setIsVolunteerWorkChecked(false);

        // set country dropdown to default
        setSelectedCountry("");

        // open modal alert
        setIsModalAlertOpen(true);

        // set form error response back to default
        setResponse({
          type: "",
          text: "",
        });
      })
      .catch((error) => {
        // set button loading state to false
        setIsButtonLoading(false);
        setResponse({
          type: "fail",
          text: "An error occurred, try again",
        });
        setTimeout(() => {
          setResponse({
            type: "",
            text: "",
          });
        }, 5000);
      });
  };

  return (
    <>
      {isModalAlertOpen && (
        <ModalAlert
          type="success"
          title="hurray"
          subtitle="thank you for supporting us"
          closeModal={() => setIsModalAlertOpen(false)}
        />
      )}
      <section
        id="get-involved"
        className="bg-gradient-to-br from-[#dae2eb] via-[#f5f2f0] to-[#e6ae89] w-full px-5 py-14 gap-10 flex flex-col md:items-center md:px-14 md:py-20 lg:px-20 lg:flex-row lg:justify-between lg:items-stretch"
      >
        <form ref={formRef} onSubmit={handleSubmit} className="w-full lg:w-3/6">
          <h3 className="uppercase tracking-widest font-medium mb-3 text-primary text-sm md:text-base">
            Get Involved
          </h3>
          <p className="font-semibold text-xl tracking-wider mb-5 md:text-2xl">
            Support The Talent Program
          </p>
          <div className="mb-5">
            <TextField
              title="Full name"
              name="full_name"
              placeholder={"John Doe"}
              isRequired
              value={inputValues.full_name}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="mb-5">
            <TextField
              title="Email Address"
              type="email"
              name="email"
              isRequired
              placeholder={"example@example.com"}
              value={inputValues.email}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="mb-5">
            <TextField
              title="Phone"
              type="tel"
              name="phone"
              isRequired
              placeholder={"+1 888 888 88"}
              value={inputValues.phone}
              handleInputChange={handleInputChange}
            />
          </div>
          <p className="mb-2  text-dark font-medium capitalize">Country *</p>
          <div className="mb-5  relative w-full">
            <div
              onClick={() => setIsCountryDropdownOpen(true)}
              className=" bg-transparent text-light text-sm px-4 py-[10px] cursor-pointer flex gap-2 items-center justify-between border-2 border-[#ababab]"
            >
              <p className="select-none">{selectedCountry} </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <ul
              ref={dropdownRef}
              className={`w-full bg-[#fffcfa]/60 backdrop-blur-lg font-medium text-left mt-2 shadow-md  rounded-lg  overflow-y-auto transition top-10 left-0 absolute z-10 ${
                isCountryDropdownOpen ? "max-h-72 py-2 px-3" : "max-h-0"
              }`}
            >
              {countryList?.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setIsCountryDropdownOpen(false);
                    setSelectedCountry(item?.name);
                  }}
                  className={` px-2 py-3 text-center text-sm cursor-pointer transition border-b border-[#dbdbdb] ${
                    item?.name === selectedCountry
                      ? "bg-primary text-white"
                      : "hover:bg-primary/25"
                  }`}
                >
                  {item?.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-5">
            <p className="mb-3  text-dark font-medium ">
              How would you like to support the Blache Talent Program? *{" "}
              <span className="text-sm whitespace-nowrap font-normal">
                (Please check all that apply)
              </span>
            </p>
            <div className="flex flex-wrap gap-4 items-center">
              <div className="flex gap-x-2 items-center   ">
                <CustomCheckbox
                  name="monetary_donation"
                  checked={isMonetaryDonationChecked}
                  // handleInputChange={()=>setCheckBoxValues({...checkBoxValues, !checkBoxValues.monetary_donation })}
                  handleInputChange={() =>
                    setIsMonetaryDonationChecked(!isMonetaryDonationChecked)
                  }
                />
                <span>Monetary Donation</span>
              </div>
              <div className="flex gap-x-2 items-center  ">
                <CustomCheckbox
                  name="in_kind_donation"
                  checked={isInKindDonationChecked}
                  handleInputChange={() =>
                    setIsInKindDonationChecked(!isInKindDonationChecked)
                  }
                />
                <span>In-kind Donation</span>
              </div>
              <div className="flex gap-x-2 items-center  ">
                <CustomCheckbox
                  name="volunteer_work"
                  checked={isVolunteerWorkChecked}
                  handleInputChange={() =>
                    setIsVolunteerWorkChecked(!isVolunteerWorkChecked)
                  }
                />
                <span>Volunteer Work</span>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <p>If you selected In-kind Donation, please specify:</p>
            <TextField
              type="text"
              name="in_kind_method"
              value={inputValues.in_kind_method}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="mb-5">
            <p>If you selected volunteer Work, please specify:</p>
            <TextField
              type="text"
              name="volunteer_method"
              value={inputValues.volunteer_method}
              handleInputChange={handleInputChange}
            />
          </div>

          {response.text && (
            <p
              className={` my-2 text-sm p-2 text-center first-letter:capitalize shadow-sm ${
                response.type === "success"
                  ? "bg-[#d8f7d7] text-[#39a030]"
                  : "bg-[#ffe5de] text-[#d94141]"
              }`}
            >
              {response.text}
            </p>
          )}

          <button
            disabled={
              !inputValues.full_name ||
              !inputValues.email ||
              !inputValues.phone ||
              !selectedCountry ||
              !(
                isMonetaryDonationChecked ||
                isInKindDonationChecked ||
                isVolunteerWorkChecked
              )
            }
            type="submit"
            className="block  w-48 h-12 mt-7  uppercase font-medium tracking-wide text-white outline-none bg-primary enabled:hover:bg-[#dc7226] disabled:bg-gray-400 transition rounded-sm"
          >
            {isButtonLoading ? (
              <svg
                className="mx-auto animate-spin"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 1.66671C8.35186 1.66671 6.7407 2.15545 5.37029 3.07113C3.99988 3.9868 2.93177 5.28829 2.30104 6.81101C1.67031 8.33373 1.50529 10.0093 1.82683 11.6258C2.14837 13.2423 2.94204 14.7272 4.10748 15.8926C5.27292 17.058 6.75778 17.8517 8.37429 18.1733C9.99079 18.4948 11.6664 18.3298 13.1891 17.699C14.7118 17.0683 16.0133 16.0002 16.929 14.6298C17.8446 13.2594 18.3334 11.6482 18.3334 10"
                  stroke="#F9F9F9"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              "Submit"
            )}
          </button>
        </form>

        <img
          src={DonationImage}
          alt=""
          className="w-full object-cover md:w-4/6 lg:w-3/6"
        />
      </section>
    </>
  );
};

export default Donation;
