import About from "../components/About";
import Footer from "../layouts/Footer";
import Hero from "../components/Hero";
import NavbarWhite from "../layouts/NavbarWhite";
import Partners from "../components/Partners";
import VideoIllustration from "../components/VideoIllustration";
import Works from "../components/Works";
import Donation from "../components/Donation";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  return (
    <>
      <main>
        <section className="relative backdrop-blur-md bg-heroImage bg-cover bg-[60%]  bg-no-repeat min-h-screen flex items-center md:bg-center">
          <div className="bg-black/20 w-full h-full absolute top-0"></div>
          <NavbarWhite />
          <Hero />
          <HashLink
            to="/#about"
            smooth
            className="cursor-pointer absolute left-1/2 -translate-x-1/2 bottom-12 w-6 md:w-8 "
          >
            <svg
              className="stroke-white hover:stroke-primary_light transition"
              viewBox="0 0 42 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 2L22.5 21L40.5 2" strokeWidth="3" />
              <path d="M2 16L22.5 35L40.5 16" strokeWidth="3" />
            </svg>
          </HashLink>
        </section>
        <About />
        <Partners />
        <Works />
        <VideoIllustration />
        <Donation />
      </main>
      <Footer />
    </>
  );
};

export default Home;
